import { Box, CssBaseline, styled, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useState } from "react";
import Footer from "./Footer";

const Main = styled("main")(({ theme }) => ({
  width: "100%",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  background: theme.palette.background.default,
  position: "relative",
}));

const Content = styled("div")({
  flexGrow: 1,
  overflow: "auto",
});

const MainLayout = () => {
  const theme = useTheme();
  const [sidebarDrawerOpen, setSidebarDrawerOpen] = useState(false);
  const toggleSidebarDrawer = (open) => {
    setSidebarDrawerOpen(open);
  };
  return (
    <Box display="flex" minHeight="100vh">
      <CssBaseline />
      <Sidebar
        sidebarDrawerOpen={sidebarDrawerOpen}
        toggleSidebarDrawer={toggleSidebarDrawer}
        setSidebarDrawerOpen={setSidebarDrawerOpen}
      />
      <Main theme={theme}>
        <Header toggleSidebarDrawer={toggleSidebarDrawer} />
        <Content>
          <Outlet />
        </Content>
        <Footer />
      </Main>
    </Box>
  );
};

export default MainLayout;
