// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project imports
import authReducer from "./slices/Auth";
import commonReducer from "./slices/common";
import categoryReducer from './slices/categories';
import forecastReducer from './slices/forecasts';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      keyPrefix: 'user-info',
    },
    authReducer
  ),
  commonReducer: persistReducer(
    {
      key: 'common',
      storage,
      keyPrefix: 'common-info',
    },
    commonReducer
  ),
  category: categoryReducer,
  forecast: forecastReducer,
});

export default reducer;
