// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  authData: {},
  categoriesList: [],
  categoriesCount: 0,
  subCategoriesList: [],
  subCategoriesCount: 0,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    categoriesSuccess(state, action) {
      state.categoriesList = action.payload?.data;
      state.categoriesCount = action.payload?.total;
    },

    subCategoriesSuccess(state, action) {
      state.subCategoriesList = action.payload?.data;
      state.subCategoriesCount = action.payload?.total;
    },
  },
});

// Reducer
export default slice.reducer;
export const { hasError, categoriesSuccess, subCategoriesSuccess } =
  slice.actions;

// ----------------------------------------------------------------------
export function getCategoriesList(skip, limit) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `/portfolios?skip=${skip}&limit=${limit}`
      );
      dispatch(categoriesSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function getSubCategoriesList(skip, limit) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/assets?skip=${skip}&limit=${limit}`);
      dispatch(subCategoriesSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function getSubCategoriesListByCategory(payload) {
  return async (dispatch) => {
    try {
      let response = '';
      if (payload?.type === 'assets') {
        response = await axios.get(
          `/portfolios/${payload?.categoryId}/assets?skip=${payload?.skip}&limit=${payload?.limit}`
        );
      } else {
        response = await axios.get(
          `/portfolios?skip=${payload?.skip}&limit=${payload?.limit}`
        );
      }
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function postSubCategories(payload, formData) {
  console.log('payload', payload);
  const queryString = new URLSearchParams(payload).toString();
  return async (dispatch) => {
    try {
      let response = '';
      if (payload?.type === 'assets') {
        response = await axios.post(`/assets?${queryString}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await axios.post(`/portfolios?${queryString}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }

      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function updateAssetsImage(payload, formData) {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/assets/image/${payload?.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function updatePortfolioImage(payload, formData) {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `/portfolios/image/${payload?.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function putSubCategory(payload, formData) {
  return async (dispatch) => {
    try {
      let response = '';
      if (payload?.type === 'assets') {
        response = await axios.put(
          `/assets/${payload?.id}`,
          payload || formData
        );
      } else {
        response = await axios.put(
          `/portfolios/${payload?.id}`,
          payload || formData
        );
      }
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function deleteSubCategory(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/assets/${payload?._id}`);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
export function deletePortfolios(payload) {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/portfolios/${payload}`);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
