import { Box, Button, Grid } from "@mui/material";
import PrimaryButton from "ui-component/button/PrimaryButton";
import AnimateButton from "ui-component/extended/AnimateButton";
import Editor from "./Editor";
import { useDispatch } from 'store';
import { useEffect, useState } from 'react';
import {
  getSubCategoriesListByCategory,
  putSubCategory,
} from 'store/slices/categories';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';

const Methodology = () => {
  const dispatch = useDispatch();

  const { categoryId, subCategoryId } = useParams();
  const [content, setContent] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  useEffect(() => {
    const payload = {
      categoryId: categoryId,
      skip: 0,
      limit: 10,
      type: subCategoryId ? 'assets' : 'portfolio',
    };
    dispatch(getSubCategoriesListByCategory(payload))
      .then((res) => {
        if (res?.status === 200) {
          const data = res.data?.data || res.data;
          let selectedItem = {};
          if (payload?.type === 'assets') {
            selectedItem = data.find(
              (item) => item.id === subCategoryId || item._id === subCategoryId
            );
          } else {
            selectedItem = data.find(
              (item) => item.id === categoryId || item._id === categoryId
            );
          }
          console.log('selectedItem?.methodology', selectedItem?.methodology);
          setContent(selectedItem?.methodology || '');
          console.log('selectedItem-----', selectedItem);
          setSelectedSubCategory(selectedItem);
        }
      })
      .catch((err) => {});
  }, [dispatch, subCategoryId, categoryId]);
  const handleSave = () => {
    console.log('selectedSubCategory', selectedSubCategory);
    const payload = {
      ...selectedSubCategory,
      methodology: content,
      id: subCategoryId || categoryId,
      type: subCategoryId ? 'assets' : 'portfolio',
    };
    console.log('payload', payload);
    dispatch(putSubCategory(payload))
      .then((res) => {
        if (res?.status === 200) {
          toast.success('Methodology updated successfully');
        }
      })
      .catch((err) => {
        toast.error(err?.detail || 'Failed to update methodology');
      });
  };
  // const handleCancel = () => {
  //   setContent(selectedSubCategory?.methodology || '');
  // };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Editor content={content} setContent={setContent} />
      </Grid>

      <Grid item xs={12}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          gap={2}
        >
          <AnimateButton>
            {/* <Button
              variant="contained"
              color="inherit"
              onClick={handleCancel}
              style={{
                padding: '8px 20px',
                boxShadow: 'none',
              }}
            >
              Cancel
            </Button> */}
          </AnimateButton>
          <AnimateButton>
            <PrimaryButton
              title="Save"
              onClick={handleSave}
              style={{
                padding: '8px 20px',
              }}
            />
          </AnimateButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Methodology;
