import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  deleteEvalmetrics,
  getEvalMetrics,
  postEvalMetrics,
  putEvalmetrics,
  uploadCSV,
} from 'store/slices/evalmetrics';
import LightButton from 'ui-component/button/LightButton';
import PrimaryButton from 'ui-component/button/PrimaryButton';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DropzoneContainer from './DropzoneContainer';
import BackTests from './BackTests';
import { getSubCategoriesListByCategory } from 'store/slices/categories';

const EditableTextField = ({ name, value, onChange, tableId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onChange({ target: { name, value: localValue } }, tableId);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        name={name}
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        variant="standard"
        size="small"
        disabled={!isEditing}
      />
      {isEditing ? (
        <IconButton onClick={handleSaveClick}>
          <SaveIcon />
        </IconButton>
      ) : (
        <IconButton onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
};

const MainBackTests = () => {
  const { categoryId, subCategoryId } = useParams();
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [headers, setHeaders] = useState('HFRI 500');
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [selectedSubCategory, setSelectedSubCategory] = useState();

  const handleHeaderChange = (event, tableId) => {
    const { name, value } = event.target;
    setHeaders((prevHeaders) => ({
      ...prevHeaders,
      [tableId === 'table1' ? 'hfri' : 'hfri500Table2']: value,
    }));
  };
  useEffect(() => {
    const payload = {
      categoryId: categoryId,
      skip: skip,
      limit: limit,
      type: subCategoryId ? 'assets' : 'portfolio',
    };
    dispatch(getSubCategoriesListByCategory(payload))
      .then((res) => {
        if (res?.status === 200) {
          const data = res.data?.data || res.data;
          let selectedItem = {};
          if (payload.type === 'assets') {
            selectedItem = data.find(
              (item) => item._id === subCategoryId || item.id === subCategoryId
            );
          } else {
            selectedItem = data.find(
              (item) => item._id === categoryId || item.id === categoryId
            );
          }

          setSelectedSubCategory(selectedItem);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch, subCategoryId, categoryId, skip, limit]);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirm = () => {
    if (selectedRow) {
      deleteMetric1(selectedRow.original._id);
      setOpenDialog(false);
      setSelectedRow(null);
    }
  };

  const columns1 = useMemo(
    () => [
      {
        accessorKey: 'metric',
        header: 'Metric',
        muiEditTextFieldProps: {
          type: 'text',
          required: true,
          error: !!validationErrors?.metric,
          helperText: validationErrors?.metric,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              metric: undefined,
            }),
        },
      },
      {
        accessorKey: 'mqe',
        header: 'MQE Strategy',
        muiEditTextFieldProps: {
          type: 'number',
          required: true,
          error: !!validationErrors?.mqe,
          helperText: validationErrors?.mqe,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              mqe: undefined,
            }),
        },
      },
      {
        accessorKey: 'hfri',
        header: 'HFRI 500',
        // header: (
        //   <EditableTextField
        //     name="hfrititle"
        //     value={headers}
        //     onChange={handleHeaderChange}
        //     tableId="table1"
        //   />
        // ),
        muiEditTextFieldProps: {
          type: 'number',
          required: true,
          error: !!validationErrors?.hfri,
          helperText: validationErrors?.hfri,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              hfri: undefined,
            }),
        },
      },
    ],
    [validationErrors, headers.hfri]
  );

  function useGetMetrics(queryKey) {
    return useQuery({
      queryKey: [queryKey],
      queryFn: async () => {
        const response = await dispatch(
          getEvalMetrics({
            assetId: subCategoryId || categoryId,
            limit: limit,
            skip: skip,
            type: subCategoryId ? 'assets' : 'portfolio',
          })
        );
        return response?.data;
      },
      refetchOnWindowFocus: false,
    });
  }
  useEffect(() => {
    queryClient.invalidateQueries('metrics1'); // Invalidate the query to refetch
  }, [subCategoryId, limit, skip]);

  function useCreateMetric(queryKey) {
    return useMutation({
      mutationFn: async (metric) => {
        metric = {
          ...metric,
          asset_id: subCategoryId || categoryId,
          hfrititle: headers,
        };
        await dispatch(postEvalMetrics(metric))
          .then((res) => {
            if (res.status === 200) {
              toast.success('Metric added successfully');
            }
          })
          .catch((err) => {
            toast.error(err?.detail || 'Failed to add metric');
          });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey); // Invalidate the query to refetch
      },
    });
  }

  function useUpdateMetric(queryKey) {
    return useMutation({
      mutationFn: async (metric) => {
        metric = {
          ...metric,
          asset_id: subCategoryId || categoryId,
          hfrititle: headers,
          _id: selectedRow.original._id,
        };
        await dispatch(putEvalmetrics(metric))
          .then((res) => {
            if (res.status === 200) {
              toast.success('Metric updated successfully');
            }
          })
          .catch((err) => {
            toast.error(err?.detail || 'Failed to update metric');
          });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey); // Invalidate the query to refetch
      },
    });
  }

  function useDeleteMetric(queryKey) {
    return useMutation({
      mutationFn: async (metricId) => {
        await dispatch(deleteEvalmetrics({ _id: metricId }))
          .then((res) => {
            if (res.status === 200) {
              toast.success('Metric deleted successfully');
            }
          })
          .catch((err) => {
            toast.error(err?.detail || 'Failed to delete metric');
          });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey); // Invalidate the query to refetch
      },
    });
  }

  const {
    data: fetchedMetrics1 = [],
    isError: isLoadingMetricsError1,
    isFetching: isFetchingMetrics1,
    isLoading: isLoadingMetrics1,
  } = useGetMetrics('metrics1');
  const { mutateAsync: createMetric1, isPending: isCreatingMetric1 } =
    useCreateMetric('metrics1');
  const { mutateAsync: updateMetric1, isPending: isUpdatingMetric1 } =
    useUpdateMetric('metrics1');
  const { mutateAsync: deleteMetric1, isPending: isDeletingMetric1 } =
    useDeleteMetric('metrics1');

  const handleCreateMetric1 = async ({ values, table }) => {
    const newValidationErrors = validateMetric(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createMetric1(values);
    table.setCreatingRow(null); // exit creating mode
  };

  const handleSaveMetric1 = async ({ values, table }) => {
    const newValidationErrors = validateMetric(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateMetric1(values);
    table.setEditingRow(null); // exit editing mode
  };

  const openDeleteConfirmModal1 = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const table1 = useMaterialReactTable({
    columns: columns1,
    data: fetchedMetrics1,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    positionActionsColumn: 'last',
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingMetricsError1
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateMetric1,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveMetric1,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setSelectedRow(row);
              table.setEditingRow(row);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            color="error"
            onClick={() => openDeleteConfirmModal1(row)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <AnimateButton>
        <LightButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            table.setCreatingRow(true);
          }}
          title="Add Metric"
          style={{
            padding: '8px 20px',
          }}
        />
      </AnimateButton>
    ),
    state: {
      isLoading: isLoadingMetrics1,
      isSaving: isCreatingMetric1 || isUpdatingMetric1 || isDeletingMetric1,
      showAlertBanner: isLoadingMetricsError1,
      showProgressBars: isFetchingMetrics1,
    },
  });
  const handleUploadCSV = () => {
    const assetsId = subCategoryId || categoryId;
    const formData = new FormData();
    formData.append('file', file);
    dispatch(uploadCSV(assetsId, formData))
      .then((res) => {
        if (res?.data?.message === 'Invalid CSV Format') {
          toast.error(res?.data?.message);
        } else {
          queryClient.invalidateQueries('metrics1');
          setFile();
        }
      })
      .catch(() => {
        toast.error('Error uploading file: ');
      });
  };

  return (
    <Grid container spacing={3}>
      <BackTests selectedSubCategory={selectedSubCategory} />
      <Grid item xs={12}>
        <Typography variant="f16" fontWeight={500}>
          Evaluation Metrics of MQE Algorithm on {selectedSubCategory?.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DropzoneContainer
          file={file}
          setFile={setFile}
          uploadProgress={uploadProgress}
          setUploadProgress={setUploadProgress}
          title={
            'Drag and drop a CSV file here, or click the button below to select one'
          }
          buttonName={'Upload Metric CSV'}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          <AnimateButton>
            <PrimaryButton
              title="Save"
              style={{
                padding: '8px 20px',
              }}
              onClick={handleUploadCSV}
              disabled={!file}
            />
          </AnimateButton>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <MaterialReactTable table={table1} />
      </Grid>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this metric?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="info"
            sx={{ color: 'white' }}
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDialogConfirm}
            variant="contained"
            color="error"
            autoFocus
            sx={{ color: 'white' }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

function validateMetric(metric) {
  return {
    metric: !metric.metric ? 'Metric is required' : '',
    mqe: !metric.mqe ? 'MQE Strategy is required' : '',
    hfri: !metric.hfri ? 'HFRI 500 is required' : '',
  };
}

const queryClient = new QueryClient();

const BackTestWithProviders = () => (
  <QueryClientProvider client={queryClient}>
    <MainBackTests />
  </QueryClientProvider>
);

export default BackTestWithProviders;
