import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { useDispatch } from 'store';
import PrimaryButton from 'ui-component/button/PrimaryButton';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DropzoneContainer from './DropzoneContainer';
import { getBackTest, uploadBackTestsCSV } from 'store/slices/evalmetrics';

const BackTests = ({ selectedSubCategory }) => {
  const [file, setFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [BackTestsList, setBackTestsList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [assetsName, setAssetsName] = useState();
  const dispatch = useDispatch();
  const { categoryId, subCategoryId } = useParams();
  const fetchData = () => {
    const payload = {
      assetId: subCategoryId || categoryId,
      limit: limit,
      skip: skip,
      type: subCategoryId ? 'assets' : 'portfolio',
    };
    dispatch(getBackTest(payload)).then((res) => {
      if (res?.status === 200) {
        setBackTestsList(res?.data);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, [dispatch, subCategoryId, skip, limit, categoryId]);

  const handleUploadCSV = () => {
    const assetsId = subCategoryId || categoryId;
    const formData = new FormData();
    formData.append('file', file);
    dispatch(uploadBackTestsCSV(assetsId, formData))
      .then((res) => {
        if (res?.data?.message === 'Invalid CSV Format') {
          toast.error(res?.data?.message);
        } else {
          fetchData();
          setFile();
        }
      })
      .catch((err) => {
        toast.error('Error uploading file: ');
      });
  };
  const dates = BackTestsList.map((item) => item.date) || [];
  const exchangeRates = BackTestsList.map((item) => item.exchange_rate) || [];
  const mqeData = BackTestsList.map((item) => item.mqe) || [];
  const sp500Data = BackTestsList.map((item) => item.sp500) || [];

  const chartOptions = {
    chart: {
      type: 'area', // Use 'area' to get the filled chart
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth', // Make the lines smooth
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.1,
      },
    },
    markers: {
      size: 4, // Control the size of the data point markers
      colors: ['#fff'],
      strokeColors: ['#00BAEC', '#77B6EA', '#545454'], // Different colors for different series
      strokeWidth: 2,
    },
    title: {
      text: '',
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // alternating row colors
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: dates || [],
      title: {
        text: 'Date',
      },
    },
    yaxis: {
      title: {
        text: 'Cumulative Profits in %',
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };

  const chartSeries = [
    {
      name: 'MQE',
      data: mqeData || [],
    },
    {
      name: 'S&P 500',
      data: sp500Data || [],
    },
    {
      name: 'HARI 500',
      data: exchangeRates || [],
    },
  ];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight={500}>
          Backtest of MQE Algorithm on {selectedSubCategory?.name}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <DropzoneContainer
          file={file}
          setFile={setFile}
          uploadProgress={uploadProgress}
          setUploadProgress={setUploadProgress}
          title={
            'Drag and drop a CSV file here, or click the button below to select one'
          }
          buttonName={'Upload CSV'}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          <AnimateButton>
            <PrimaryButton
              title="Save"
              style={{
                padding: '8px 20px',
              }}
              onClick={handleUploadCSV}
              disabled={!file}
            />
          </AnimateButton>
        </Box>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="area" // Use 'area' for a filled chart
          height={350}
        />
      </Grid>
    </>
  );
};

export default BackTests;
