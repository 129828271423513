import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";

const StyledDiv = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  width: "100%",
  background: "linear-gradient(-180deg,#284476,#506489)",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1, 0),
  },
}));

const WrapperStack = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "baseline",
  gap: theme.spacing(3),

  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
    height: "50px",
    width: "50px",
  },
}));

const Header = ({ toggleSidebarDrawer }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/login");
    handleMenuClose();
  };

  return (
    <StyledDiv>
      <Container>
        <WrapperStack>
          <Stack
            onClick={handleMenuOpen}
            direction={'row'}
            alignItems={'center'}
            gap={1}
            sx={{
              cursor: 'pointer',
            }}
          >
            <IconButton>
              <Avatar alt="User Avatar" src="" />
            </IconButton>
            <Typography variant="f16" fontWeight={600} color={'common.white'}>
              Admin
            </Typography>
          </Stack>

          <MenuButton onClick={() => toggleSidebarDrawer(true)}>
            <MenuIcon sx={{ color: '#ffffff' }} />
          </MenuButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem sx={{ minWidth: 180 }} onClick={handleLogout}>
              <LogoutIcon sx={{ mr: 1 }} />
              Logout
            </MenuItem>
          </Menu>
        </WrapperStack>
      </Container>
    </StyledDiv>
  );
};

export default Header;
