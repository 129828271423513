import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const isAuthenticated = () => {
  const token = localStorage.getItem('ucla-token');
  console.log('Token:', token);
  return !!token;
};
const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();

  const authenticated = isAuthenticated();

  useEffect(() => {
    if (!authenticated) {
      navigate('/login', { replace: true });
    }
  }, [authenticated, navigate]);

  return authenticated ? children : null;
};

export default PrivateRoute;
