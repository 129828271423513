import axios from 'axios';
  console.log('process.env.REACT_APP_BASE_URL', process.env.REACT_APP_BASE_URL);
const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosServices.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('ucla-token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// interceptor for http
axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('error.response?.data?.detail', error.response?.data?.detail);
    if (error.response && error.response.status === 401) {
      // Check if the current route is not '/login'
      if (window.location.pathname !== '/login') {
        // Redirect to login page
        window.location.href = '/login';
      }
    }
    return Promise.reject(
      (error.response && error.response?.data?.detail) ||
        'Something went wrong, Please try again later'
    );
  }
);

export default axiosServices;
