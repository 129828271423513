import MainLayout from 'layout/MainLayout';
import Content from 'views/content';
import PrivateRoute from './PrivateRoute';

const MainRoutes = {
  path: '/',
  element: (
    <PrivateRoute>
      <MainLayout />
    </PrivateRoute>
  ),
  children: [
    {
      path: '/assets/:categoryId/:subCategoryId',
      element: (
        <PrivateRoute>
          <Content />
        </PrivateRoute>
      ),
    },
    {
      path: '/assets/:categoryId',
      element: (
        <PrivateRoute>
          <Content />
        </PrivateRoute>
      ),
    },
  ],
};

export default MainRoutes;
